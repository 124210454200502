exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-page-account-tsx": () => import("./../../../src/templates/page-account.tsx" /* webpackChunkName: "component---src-templates-page-account-tsx" */),
  "component---src-templates-page-default-tsx": () => import("./../../../src/templates/page-default.tsx" /* webpackChunkName: "component---src-templates-page-default-tsx" */),
  "component---src-templates-page-home-tsx": () => import("./../../../src/templates/page-home.tsx" /* webpackChunkName: "component---src-templates-page-home-tsx" */),
  "component---src-templates-page-on-site-service-tsx": () => import("./../../../src/templates/page-on-site-service.tsx" /* webpackChunkName: "component---src-templates-page-on-site-service-tsx" */),
  "component---src-templates-page-order-tsx": () => import("./../../../src/templates/page-order.tsx" /* webpackChunkName: "component---src-templates-page-order-tsx" */)
}

